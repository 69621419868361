import React, { useEffect, useState } from "react"
import Autocomplete from "react-google-autocomplete"
import { navigate } from "gatsby"
import Select from "react-select"
import GoogleMapsAutocomplete from "./googleautosuggest"
import "lazysizes"
const HomeSearchCitySelector = props => {
  const [spacetype, setspacetype] = useState("meetingSpace")
  const [city, setCity] = useState("")
  const [latValue, setlatValue] = useState("")
  const [lngValue, setlngValue] = useState("")
  const [buttonState, setButtonState] = useState(false)
  const [firsturl, setfirsturl] = useState("")
  const [seatCapacity, setseatCapacity] = useState(0)
  const [locality, setLocality] = useState("")
  const cityOptions = props.city.map(city => ({
    value: city.node.city,
    label: city.node.city,
  }))

  function seatChange(e) {
    setseatCapacity(e.target.value)
  }

  // const doSomething = place => {
  //   let address = place.formatted_address,
  //     location = address.split(",")[0],
  //     citys = address.split(",")[1],
  //     state = address.split(",")[2]

  //   location = location.trim()

  //   setfirsturl(
  //     "/search?city=" +
  //       city +
  //       ", " +
  //       +"&lat=" +
  //       latValue +
  //       "&lng=" +
  //       lngValue +
  //       "&spaceType=" +
  //       spacetype
  //   )
  // }

  function changeSpaceType(value) {
    setspacetype(value)
    if (!value) {
      // setCity("")
    }
  }

  const changeCity = selectedOption => {
    setCity(selectedOption.value)
  }

  const SearchResult = () => {
    navigate(firsturl)
  }

  // Center the autocomplete to Bengaluru by default
  // const bengaluruCenter = { lat: 12.9716, lng: 77.5946 }

  useEffect(() => {
    if (latValue == "") {
      function createSlug(title) {
        return title
          .toLowerCase() // Convert to lowercase
          .trim() // Remove extra spaces
          .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with hyphens
          .replace(/^-+|-+$/g, "") // Remove leading/trailing hyphens
      }
      const citySlug = createSlug(city)
      if (spacetype == "meetingSpace") {
        if (seatCapacity === 0) {
          setfirsturl(
            "/meeting-spaces/" +
              citySlug +
              "/all?locality=All&purpose=AnySpaces&priceOption=&seat=0&minSeat=0&maxSeat=20000"
          )
        } else {
          setfirsturl(
            "/meeting-spaces/" +
              citySlug +
              "/all?locality=All&purpose=AnySpaces&priceOption=&seat=" +
              seatCapacity +
              "&minSeat=0&maxSeat=20000"
          )
        }
      } else if (spacetype == "dailyofficeSpace") {
        setfirsturl("/coworking-spaces/" + citySlug + "/all")
      } else {
        setfirsturl("/office-spaces/" + citySlug + "/all")
      }
    } else {
      setfirsturl(
        "/search?city=" +
          city +
          "&lat=" +
          latValue +
          "&lng=" +
          lngValue +
          "&spaceType=" +
          spacetype +
          "&location=" +
          locality +
          "&capacity=" +
          seatCapacity
      )
      console.log(
        "/search?city=" +
          city +
          "&lat=" +
          latValue +
          "&lng=" +
          lngValue +
          "&spaceType=" +
          spacetype +
          "&location=" +
          locality +
          "&capacity=" +
          seatCapacity
      )
    }
  }, [latValue, city, lngValue, spacetype, locality, seatCapacity])
  console.log(firsturl, spacetype)
  const geoLocation = (lat, lng, locality) => {
    setlatValue(lat)
    setlngValue(lng)
    setLocality(locality)
    setButtonState(true)
  }
  return (
    <div className="homePageSearch">
      <div className="whiteBox">
        <div className="row aligner" style={{ padding: "15px 20px" }}>
          <div
            className={
              spacetype === "meetingSpace"
                ? "col-md-4 col-4 spaceTypeButton active"
                : "col-md-4 col-4 spaceTypeButton "
            }
            onClick={() => changeSpaceType("meetingSpace")}
          >
            <img
              data-src="https://assets.gofloaters.com/icons/svg/meeting-rooms.svg"
              alt="Meeting Room Icon"
              className="lazyload"
            ></img>
            Meeting Rooms
          </div>
          <div
            className={
              spacetype === "dailyofficeSpace"
                ? "col-md-4 col-4 spaceTypeButton active"
                : "col-md-4  col-4 spaceTypeButton "
            }
            onClick={() => changeSpaceType("dailyofficeSpace")}
          >
            {" "}
            <img
              data-src="https://assets.gofloaters.com/icons/svg/day-pass.svg"
              className="lazyload"
              alt="Day Pass Icon"
            ></img>{" "}
            Day Pass
          </div>
          <div
            className={
              spacetype === "monthlyofficeSpace"
                ? "col-md-4  col-4 spaceTypeButton active"
                : "col-md-4  col-4 spaceTypeButton "
            }
            onClick={() => changeSpaceType("monthlyofficeSpace")}
          >
            {" "}
            <img
              data-src="https://assets.gofloaters.com/icons/svg/coworking-office.svg"
              className="lazyload"
              alt="Coworking Icon"
            ></img>{" "}
            Coworking Office
          </div>
        </div>
        <div>
          <div className="input-group-search-home panel-search">
            <div className="item">
              <label htmlFor="citySelect">Select City</label>
              <Select
                options={cityOptions} // Options for the searchable dropdown
                onChange={changeCity} // Event handler for city change
                isDisabled={!spacetype} // Disable if no spacetype selected
                value={cityOptions.find(option => option.value === city)} // Set the selected value
                placeholder="Select City"
                className="form-control homesearchformnew paddingRight"
              />
            </div>
            <div className="item">
              {city && spacetype ? (
                <GoogleMapsAutocomplete
                  city={city}
                  onGeoSelected={geoLocation}
                />
              ) : (
                <select
                  className="form-control homesearchformnew paddingRight"
                  disabled
                >
                  <option value="">Locality</option>
                </select>
              )}
            </div>
            {spacetype === "meetingSpace" ? (
              <div className="item">
                <select
                  className="form-control homesearchformnew paddingRight"
                  onChange={seatChange}
                >
                  <option value="0">Capacity</option>
                  <option value={4}>4+ Seats</option>
                  <option value={6}>6+ Seats</option>
                  <option value={8}>8+ Seats</option>
                  <option value={10}>10+ Seats</option>
                  <option value={12}>12+ Seats</option>
                  <option value={15}>15+ Seats</option>
                  <option value={20}>&gt; 20+ Seats</option>
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="colorBox">
        <div className="text-center">
          <button
            onClick={SearchResult}
            className="NewHomeSearchSelect"
            value="Search"
          >
            View Spaces
          </button>
        </div>
      </div>
    </div>
  )
}

export default HomeSearchCitySelector
